import React, { Component } from "react";

const PortfolioList = [
  {
    image: "image-1",
    category: "Servicio",
    title: "Diseño + Contrucción",
    link: "/diseño-construccion",
  },
  {
    image: "image-2",
    category: "Servicio",
    title: "Almacenaje de Herramientas",
    link: "/almacenaje-herramientas",
  },
  {
    image: "image-3",
    category: "Servicio",
    title: "Pisos",
    link: "/lifts",
  },
  {
    image: "image-4",
    category: "Servicio",
    title: "Elevador para Vehiculo",
    link: "/elevador-vehiculo",
  },
  {
    image: "image-5",
    category: "Servicio",
    title: "Puertas de Cochera",
    link: "/puertas-cochera",
  },
  {
    image: "image-6",
    category: "Servicio",
    title: "Complementos",
    link: "/complementos",
  },
];

class PortfolioMasonry extends Component {
  render() {
    const { column } = this.props;
    console.log("hi", column);
    const list = PortfolioList.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className="Tilt-inner">
              <div className="portfolio">
                <div className="thumbnail-inner">
                  <div className={`thumbnail ${value.image}`}></div>
                  <div className={`bg-blr-image ${value.image}`}></div>
                </div>
                <div className="content">
                  <div className="inner">
                    <p>{value.category}</p>
                    <h4>
                      <a href={value.link}>{value.title}</a>
                    </h4>
                    {/* <div className="portfolio-button">
                      <a className="rn-btn" href={value.link}>
                        Conocer mas
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioMasonry;
